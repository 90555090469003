.outer{
    display: flex;
    justify-content: space-evenly;
    /* border:2px solid black; */
}
.icon-large {
    font-size: 50rem; /* Set the desired size (e.g., 24px) */
    /* border: 2px solid rgb(6, 131, 41); */
  }
.adiv{
    margin-top: 3rem;
    /* border: 2px solid red; */
    text-align: left;
    
}
.paymentButtons{
    display: flex;
    flex-direction: column;
    margin-top: 2.4rem;
    margin-Left: 2rem;
    width:20rem;
    /* color: #C5A7CF; */
    /* height:10rem; */
    /* border:2px solid red; */
}
.newdiv{
    display: inline-block;
    /* border:2px solid rgb(0, 255, 85); */
    /* width: 10rem; */
    margin-Left: 30rem;
    height:20rem;
    /* margin-bottom: 10rem; */   
}



.col{
    /* border: 2px solid red; */
    display:inline-block;
    width:10rem;

}