body, html {
    /* margin: 5rem 5rem 5rem 5rem; */
    padding: 0;
    height: 100%;
  }
  
  #map {
    /* border: 10px solid black; */
    height: 100%;
    width: 100%;
  }
  