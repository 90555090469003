.container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    /* background-color: #F0E5F3; */
    background-image: linear-gradient(to bottom, #F0E5F3 40%, rgb(138, 45, 125) 100% );
    position: relative;
  }
  
  .logo {
    position: absolute;
    top: 20px;
    left: 20px;
    background: url('../../../assets/LOGO.png');
    background-position: top left;
    background-repeat: no-repeat;
    background-size: 200px;
    width: 200px;
    height: 200px;
  }
  
  .form-container {
    width: 35rem;
    height: 33rem;
    background-color: white;
    border-radius: 20px;
    padding: 20px;
    overflow: hidden;
    margin-top: 20px;
  }

 

  #parentContainer .form-control.rounded-0 {
    background-color: #f8f9fa !important;
    border-color: #ced4da !important;
    width: 200px !important;
  }
  
  .form-control.rounded-0:focus {
    box-shadow: none; /* Remove the default focus box shadow */
  }

  .custom-input::placeholder {
    color: red; /* Replace 'red' with your desired color */
  }
.remember-forgot {
    display: flex;
    justify-content: flex-end;
    margin: -1.2rem 8rem 1rem 0rem;
    /* border: 2px solid black; */
}

.remember-forgot span {
    font-size: 12px;
    margin-right: right;
}
.remember-font{
    font-size: 15px;
    margin-top: 22px;
    /* font-Family: Arial; */
}
.custom-btn {
    background-color: #8A31A8;
    color: #fff;
    border-radius: 5px;
    /* width: 235px; */
    width:61%;
    height: 28px;
    font-size: 13px;
    border: none;
}

.background {
    background-color  :#FFF2FD}

.Label{
    font-weight: bolder !important;
    color: #2EA2EC;
}

.Row{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 55%;
    offset: 12px;
    margin: auto;
}
.FormItem{
    /* background-color: turquoise; */
    width: 40%;
    text-align: center;
    margin: auto;
    backdrop-filter: blur(2px);
}
.InvalidLabel{
    font-weight: bolder !important;
    color: red;
}

.Input{
    width: 90%;
    background-color: white;
    border-color: white;
    /* box-Shadow: 0 8px 16px 0 rgba(0,0,0,0.2); */
    padding: 0.5rem 1rem !important;
}

.Input:focus{
    background-color: white;
    border-color: #2EA2EC;
    box-Shadow: 0 8px 16px 0 rgba(32, 120, 179, 0.2);
    padding: 0.5rem 1rem !important;
}
.InvalidInput{
    width: 100%;
    background-color: white;
    padding: 0.5rem 1rem !important;
    box-Shadow: 0 8px 16px 0 rgba(223, 32, 32, 0.527);
}
.InvalidInput:focus{
    background-color: white;
    border-color: #2EA2EC;
    box-Shadow: 0 8px 16px 0 rgba(32, 120, 179, 0.2);
    padding: 0.5rem 1rem !important;
}

.SignUpDiv{
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem !important;
    justify-content: center !important;
    margin: auto !important;
    
}

.SignInSignUpLogo{
    text-align: center;
    width : 6rem;
    height : 6rem; 
    margin : auto;
    margin-bottom: 2rem;
    justify-content: center;
}
 .SignInSignUpbg{
   
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background:url('../../../assets/landinglogo.png');
    background-repeat: no-repeat;
    /* background-size: cover; */
    background-size: 10px;

    filter: brightness(0.9);
} 
.SignupFormbg{
    background-color: rgba(255, 255, 255, 0.33);
    box-shadow: rgba(0,0,255,0.33);
    backdrop-filter: blur(3px);
    padding: 0px 16px 16px 16px;     
    border-radius: 16px;
    
}
.ant-form ant-form-horizontal SignInPage_SignupFormbg__328Q9{
    padding: 0px 16px 16px 16px;  
}

.Create{
    text-align: center;
    white-space: nowrap;
    color: #000000;
    width: 80%;
    font-weight : 500;
    font-family: Montserrat;
    font-size: 0.8rem;
    letter-spacing: -0.54px;
    line-height: 2rem;
    border-radius: 9px;
    background-color: inherit;
    border: none;
    outline: none;
    margin-top: 1rem !important;
}
.Create:hover{
    background-color: white;
    box-shadow: 2px 2px 4px 4px rgba(58, 57, 57, 0.103), 0 3px 6px 0 rgba(112, 111, 111, 0.1);
}
.Create:focus{
    outline: none;
}

.NewButton{
    text-align: center;
    white-space: nowrap;
    color: #2EA2EC;
    font-weight : 900;
    font-family: Montserrat;
    font-size: 0.6rem;
    letter-spacing: -0.54px;
    border-radius: 9px;
    background-color: inherit;
    border: dashed;
    border-width: 1.5px;
    box-sizing: border-box;
    border-color: #2EA2EC;
    outline: none;
    padding: 0.5rem 2rem;
    background-color: white;
    margin-top: 3rem;
}

.SelectedButton{
    text-align: center;
    white-space: nowrap;
    color: white;
    font-weight : 900;
    font-family: Montserrat;
    font-size: 0.6rem;
    letter-spacing: -0.54px;
    border-radius: 9px;
    box-sizing: border-box;
    background-color: inherit;
    border: solid;
    border-color: #2EA2EC;
    outline: none;
    padding: 0.5rem 2rem;
    background-color: #2EA2EC;
    margin-top: 3rem;
}


@media only screen and (min-width: 500px)  and (orientation:portrait) {
    .SignUpDiv{
        margin-bottom: 2rem !important;
    }
    
    .SignInSignUpLogo{
        width : 7rem;
        height : 7rem; 
        /* margin-top: 7rem; */
    }
        
    .Create{
        width: 70%;
        font-size: 1.2rem;
        line-height: 2rem;
    }
    
}

@media only screen and (min-width: 500px)  and (orientation:landscape) {
    .SignUpDiv{
        margin-bottom: 2rem !important;
    }
    
    .SignInSignUpLogo{
        width : 7rem;
        height : 7rem; 
        margin-top: 15rem;
    }
        
    .Create{
        width: 70%;
        font-size: 1.2rem;
        line-height: 2rem;
    }
    
}

@media only screen and (min-width: 768px) and (orientation:portrait) {
    .SignUpDiv{
        margin-bottom: 2rem !important;
    }
    
    .SignInSignUpLogo{
        width : 8rem;
        height : 8rem; 
        margin-top: 0rem;
    }
    
    .Create{
        width: 50%;
        font-size: 1.2rem;
        line-height: 2rem;
    }

}

@media only screen and (min-width: 768px) and (orientation:landscape) {
    .SignUpDiv{
        margin-bottom: 2rem !important;
    }
    
    .Input{
        width: 60%;
    }
    
    .InvalidInput{
        width: 60%;
    }

    .SignInSignUpLogo{
        width : 7rem;
        height : 7rem; 
        margin-top: 0rem;
    }
    
    .Create{
        width: 40%;
        font-size: 1rem;
        line-height: 2rem;
        margin-top: 2rem !important;
    }

}

@media only screen and (min-width: 897px) {
    .SignUpDiv{
        margin-bottom: 2rem !important;
    }
    
    .SignInSignUpLogo{
        width : 6rem;
        height : 6rem; 
        margin-top: 0rem;
    }
        
    .Create{
        width: 50%;
        font-size: 1.2rem;
        line-height: 2rem;
        margin-top: 3rem !important;
    }

}

@media only screen and (min-width: 992px) {
    .SignUpDiv{
        margin-bottom: 2rem !important;
    }
    
    .Input{
        width: 60%;
    }

    .InvalidInput{
        width: 60%;
    }

    .SignInSignUpLogo{
        width : 8rem;
        height : 8rem; 
        margin-top: 0rem;
    }
    
    .Create{
        width: 40%;
        background-color: white;
        font-size: 1.2rem;
        line-height: 3rem;
        margin-top: 3rem !important;
    }

    .Row{
        width: 55%;
    }

    .NewButton{
        font-weight : 900;
        font-size: 0.9rem;
        padding: 0.5rem 2rem;
    }

    .SelectedButton{
        font-weight : 900;
        font-size: 0.9rem;
        padding: 0.4rem 2rem;
    }

}

@media only screen and (min-width: 1200px) {
    .Input{
        width: 50%;
    }
    
    .InvalidInput{
        width: 50%;
    }
    .SignInSignUpLogo{
        width : 8rem;
        height : 8rem; 
        margin-top: 0rem;
    }
        
    .Create{
        width: 35%;
        background-color: white;
        font-size: 1.2rem;
        line-height: 3rem;
        margin-top: 3rem !important;
    }

    .Row{
        width: 45%;
    }

}

@media only screen and (min-width: 1400px) {
    .SignUpDiv{
        margin-bottom: 2rem !important;
    }
    
    .Input{
        width: 50%;
    }

    .InvalidInput{
        width: 50%;
    }

    .SignInSignUpLogo{
        width : 10rem;
        height : 10rem; 
        margin-top: 0rem;
    }

    .Create{
        width: 40%;
        font-size: 1.4rem;
        line-height: 2.6rem;
    }

    .NewButton{
        margin-top: 3rem;
    }

    .SelectedButton{
        margin-top: 3rem;
    }

}

@media only screen and (min-width: 1600px) {
    .SignUpDiv{
        margin-bottom: 2rem !important;
    }
    
    .Input{
        width: 50%;
    }

    .InvalidInput{
        width: 50%;
    }

    .SignInSignUpLogo{
        width : 9rem;
        height : 9rem; 
        margin-top: 0rem;
    }
    
    .Create{
        width: 40%;
        font-size: 1.5rem;
        line-height: 2.3rem;
    }

    
}
/* Add media query for screens with a maximum width of 768px */

/* Add media query for screens with a maximum width of 768px */
@media (max-width: 768px) {
    .form-container {
      width: 90%; /* Adjust the width as needed for smaller screens */
    }
  
    .logo {
      /* Adjust logo styles for smaller screens */
      background-size: 150px; /* Reduce the logo size */
    }
  
    h2 {
      /* Adjust the font sizes for smaller screens */
      font-size: 24px;
      margin-top: 20px;
    }
  
    input {
      /* Adjust input styles for smaller screens */
      font-size: 16px;
      width: 100%; /* Make the input full width */
      height: 60px; /* Reduce the input height */
    }
  
    /* Adjust the "Forgot Password" link styles for smaller screens */
    .remember-forgot {
        display: flex;
        justify-content: space-around;
        margin: -1.2rem 0rem 1rem 0rem;
        /* border: 20px solid rgb(238, 34, 34); */
    }

  
    /* Additional adjustments for other elements as needed */
    /* For example, the "Sign up" text could be made smaller */
    .remember-font {
      font-size: 14px;
    }
  
    /* Custom button adjustments for smaller screens */
    .custom-btn {
      font-size: 18px;
    }
  }