#topbox{
    display: flex; 
    flex-direction: row;
    /* border: 2px solid black; */
    justify-content: space-between;
    margin-bottom: 4rem;
}

.myLinks{
    text-decoration: none;
    /* border: 2px solid rgb(182, 126, 225); */
}

.sectionB{
    display: flex;
    flex-direction: row;
    /* border: 2px solid black; */
}

.sectionB > div{
    /* border: 2px springgreen solid; */ 
    height: 25rem;
    margin: 0.2rem;
}


.amt{
    color: #8A31A8;
    font-weight: bolder;
}
#firstD{
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */
    background: linear-gradient(to right, #e0cde6, #fff);
    box-shadow: inset 1px 2px 7px black;
    border-radius: 7px;
    color: rgb(65, 30, 94);
    font-weight: lighter;

}

#secondD{
    /* border: 2px black solid; */

    background: linear-gradient(to bottom, #D9BFE0, #fff);
    box-shadow: 1px 2px 10px black; 
    overflow: hidden; 
     
    
}
#secondD > h5,h3{
    margin: 0px;
    font-weight:bolder;
}

.disabled-link {
    pointer-events: none; /* Disable pointer events */
    opacity: 0.6; /* Reduce opacity to indicate it's disabled */
    text-decoration: none; /* Remove underlines if any */
    cursor: not-allowed; /* Change cursor to not-allowed icon */
    /* You can add more styles as needed */
  }