*{
    box-sizing: border-box;
    
}



#search{
    /* border: 2px solid black; */
    box-shadow: 5px 8px 18px #888888;
    border-radius: 10px;
}

.subcol{
    box-shadow: 0px 2px 5px rgb(94, 90, 91) inset;
}

.col{
    border-radius: 5px;
    /* margin-right: 1rem; */
}

.mycol{
    /* border: 2px solid red; */
    box-shadow: 5px 8px 18px #888888

}


