#cominghead{
    font-size: 1rem;
    

    /* font-weight: lighter; */
}

.inner{
    display:flex;
    /* border: 2px solid black; */
    flex-direction: column;
    color: #2e1236;


}

.tle{

   color: #2c1735;
   font-size: small;
   
}


/* make css media query for the 280 px screen and smaller */
/* make css media query for the 45167 px screen and bigger */