.mydata{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}



@media (min-width: 1599px) {
    .mydata ul li{
        /* border: 10em dotted magenta; */
        font-size: 2em;
        margin-bottom: 1rem;
    }
}

@media (max-width: 999px) {  
    .mydata{
        /* border: 5px solid green; */
        display: flex;
        flex-direction: column;
       
    }

    .readmoreimg{
        /* border: 5px solid black; */
        overflow: hidden;
    }

}
