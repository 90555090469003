#cominghead{
    font-size: 3.5rem;
    margin-top: 2rem;
    margin-left: -5rem;
    
    
    /* border: 2px solid black; */
    /* font-weight: lighter; */
}

.inner{
    display:flex;
    flex-direction: column;
    color: #531967;
    
    
}

.tle{
    
    color: #782495;
    font-size: large;
    margin-top: -1rem;
    margin-left: -4rem;
}


/* make css media query for the 280 px screen and smaller */
/* make css media query for the 45167 px screen and bigger */