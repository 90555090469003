* {box-sizing: border-box;
    /* border: 2px solid black; */
}



.hereForma{
    /* margin: 2 2 2 2; */
    width: 100%;
    /* border: 2px solid black; */
    padding: 0rem 5rem 0rem 2rem;
}



@media (max-width: 900px) {
    #first3,#second2,#third3,#fourth3,#fifth3{
        display: flex;
        flex-direction: column;
    }   
    input{
        width: 20px;
    }
  }

  input{
    height: 1.4rem;
  }


  .suggestion-container {
    position: absolute;
    z-index: 1;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    max-height: 200px;
    overflow-y: auto;
  }
  
  .suggestion-item {
    padding: 8px;
    cursor: pointer;
  }
  
  .suggestion-item:hover {
    background-color: #f0f0f0;
  }
  