.thisisthedetaisv{
    /* width: 50rem; */
    width: 50%;
    margin: auto;
    overflow: hidden;
    /* border: 5px solid green; */
}


.thisisthedetaisv span {
    font-size: smaller;
    
    /* background-color: rgb(240, 229, 243); */
    border-radius: 5px;
  }
label{
    font-weight: bolder;
    font-size: small;
}
.originLD{
    width: 28rem;
    height:2.5rem;
}
.DestLD{
    width: 28rem;
    height:2.5rem;
}
.secondLD{
    flex-direction: row;
}


/* Assuming your button has a class or ID, let's use '.cool-button' for this example */
.cool-button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
 
    color: #ffffff; /* Text color */
    background-color: #3498db; /* Background color */
    border-radius: 5px; /* Rounded corners */
    transition: background-color 0.3s, color 0.3s;
  }
  
  .cool-button:hover {
    background-color: #362424; /* Hover background color */
    color: #3498db; /* Hover text color */
    border:"2px solid black"
  }
  


  /* Styles for big screens (width 768 pixels and above) */
@media screen and (min-width: 880px) {
    .thisisthedetaisv{
        /* border:10px solid rgb(181, 181, 187); */
        /* background-color: rgb(160, 148, 148); */
        overflow: hidden;
        width: 100%;
    }
    .thisisthedetaisv span, .thisisthedetaisv label {
        font-size: larger;
        height:3rem ;
        width:5rem;
      }
      .originLD{
        display: inline-block;
        /* border:2px solid red; */
        width: 60%;
      }
      .DestLD{
        width: 100%;
    }
    .secondLD{
        /* border: 10px solid red; */
        display: flex;
        flex-direction: row;
    }
    
}



/* Styles for small screens (width below 768 pixels) */
@media screen and (min-width: 596px) and (max-width: 879px) {
    .thisisthedetaisv{
        overflow: hidden;
        width: 100%;
        border:"10px solid red";
        /* background-color: gray; */
    }
    .firstDivLD{
        display: flex;
        flex-direction: column;
        /* border: 10px solid purple; */
        width: 50%;
        margin: auto;
    }
    .thirdLD{
        /* border:2px solid black; */
        flex-direction: column;
        width: 50%;
        margin: auto;
    }
    .firstDivLD > div {
        /* border:5px solid black; */
        /* width: 50%; */
        display: flex;
        justify-content: start;

    }
    .firstDivLD > div > label{
        width:200rem;
    }
  

    .secondLD{
        /* border: 10px solid red;  */
        width: 90%;
        margin: auto;
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }
    .originLD{
        height:3.5rem;
    }
    .DestLD{
        height:3.5rem;
    }
 
  

  }


  @media screen and  (max-width: 595px) {
    .thisisthedetaisv{
        overflow: scroll;
        /* border:10px solid red; */
        /* background-color: gray; */
    }
    .firstDivLD{
        display: flex;
        flex-direction: column;
        justify-content: start;
        /* border: 7px solid red; */
    }
    .secondLD{
        /* border: 10px solid red; */
        display: flex;
        flex-direction: column;
    }
    .thirdLD{
        flex-direction: column;
    }
    .firstDivLD > div {
        /* border:5px solid black; */
    /* border:#3498db 4px sold ; */
    }
    .firstDivLD > div > label,span {
        /* border:5px solid black; */
    /* border:#3498db 2px sold ; */
    }







  }