.subscription-table-container {
  width: 80%;
  margin: 20px auto;
}

.subscription-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.subscription-table th, .subscription-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.subscription-table th {
  background-color: #f2f2f2;
}




.validation-page {
    background-color: white;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  
    /* Flexbox styles for vertical centering */
    display: flex;
    flex-direction: column;
    justify-content: center;
}
  
  /* Add the following styles to the parent container (validation-page) */
  .validation-page-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    background-color: #f0e5f3;
    margin: 0;
    padding: 0;
    /* border: 2px solid red; */
  }
  
  .header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  h1 {
    color: #333;
  }
  
  .content {
    text-align: center;
  }
  
  p {
    font-size: 16px;
    line-height: 1.5;
    color: #555;
    margin-bottom: 20px;
  }
  
  button {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  

  .footer-note {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    font-size: 12px;
    color: #777;
  }