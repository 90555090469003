* {
    box-sizing: border-box;

}



.message-container {
    position: fixed;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(52, 47, 54, 0.973);
    color: rgb(253, 247, 247);
    padding: 10px 20px;
    border-radius: 5px;
    z-index: 1000;
    display: none;
    font-weight: bolder;
  }
  


table {
    /* border: 5px solid green; */
    background-color: #f0e5f3;
    box-shadow: 0px 2px 5px rgb(94, 90, 91) inset;
    background-image: linear-gradient(to right, #f0e5f3, rgb(254, 252, 254));
    padding: 1rem;
    width: 100%;
    display: inline-block;
    height: vh;
}

#head {
    /* border: 5px solid rgb(152, 34, 34); */
    border-radius: 8px;
    box-shadow: 5px 8px 18px #888888;
    /* padding: 0rem 0rem 2rem 2rem; */
    height: 3.9em;
    margin-bottom: 1.5rem;
    background-color: #c99cd8;
    background-image: linear-gradient(to bottom, #c99cd8, rgb(254, 252, 254));
    display: inline-block;
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;

}

#row {
    /* border:2px solid black; */
    /* padding: 0.3rem; */
    padding: 0.3em 0em 0.3em 0em;
    border-radius: 5px;
    display: inline-block;
    /* margin-left: 0.2rem; */
    /* min-width:100%; */
    width: 100%;
    box-shadow: 0px 2px 5px rgb(94, 90, 91) inset;
    background-color: #c99cd8;
    background-image: linear-gradient(to bottom, #c99cd8, rgb(254, 252, 254));
    /* overflow: hidden; */
    box-sizing: border-box;
}


th {
    padding: 0rem 0rem 0rem 0rem;
    width: 100vw;

    /* border: 20px solid black; */
    /* text-align: end; */

    /* display: flex; */
    /* flex-direction:column; */
    /* justify-content: space-between; */

}

td {
    padding: 0rem 0rem 0rem 0rem;
    width: 100vw;

    text-align:center;
    /* font-size: small; */
}





/* Media query for big screens (min-width: 768px) */
@media screen and (min-width:1200px) {
   

    #head {
        /* padding: 0em 7em 2m 4em; */
        /* border: 2px solid black; */
        padding: 0rem;
        /* display: flex; */
        /* justify-content: space-between; */
        display: inline-block;
        width: vw;
    }
}

/* Media query for small  screens (max-width: 480px) */
@media screen and (max-width: 1378px) {
    table {
        display: block;

    }

    #head {
        /* padding: 0em 7em 2m 4em; */
        /* border: 6px solid rgb(44, 135, 43); */
        padding: 0rem;
        height: 5.5em;
        display: inline-block;
        width: vw;
    }

}



/* Media query for small even screens (max-width: 480px) */
@media screen and (max-width: 1190px) {
    table {
        display: block;

    }

    #head {
        /* padding: 0em 7em 2m 4em; */
        /* border: 6px solid rgb(59, 26, 190); */
        padding: 0rem;
        height: 5.5em;
        display: inline-block;
        width: vw;
    }
}