
.App{
    /* border: 2px solid black; */
    box-shadow: inset 2px 2px 10px gray;
    /* background-color: #6b7586; */
}

.searchb{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* border: 2px solid green; */
    height: 180px;
    width: 100%;
    background-color: #747e91;
    color: white;
    text-transform: uppercase;
 
}

.thisissrch{
    border-radius: 6px;
    border: 2px solid #747e91;
    height: 40px;
    width: 50%;
    color: black;
    outline: none;

}

*{
    box-sizing: border-box;
    
}


#search{
    /* border: 2px solid black; */
    box-shadow: 5px 8px 18px #888888;
    border-radius: 10px;
}

.subcol{
    box-shadow: 0px 2px 5px rgb(94, 90, 91) inset;
}

.col{
    border-radius: 5px;
}

.mycol{
    /* border: 2px solid red; */
    box-shadow: 5px 8px 18px #888888

}


