/* font-family: 'Comfortaa', cursive;
font-family: 'Heebo', sans-serif;
font-family: 'Karla', sans-serif;
font-family: 'Oswald', sans-serif;
font-family: 'Raleway', sans-serif; */

#navbar-toggle {
  display: none;
  flex-direction: column;
  cursor: pointer;
}


#insidethetoogle {
  display: none;
}

.optimus {
  /* width: 100vw; */
  /* box-sizing: border-box; */
  display: inline-block;
  width: 100vw;
  margin: 0px;
  height: 100vh;
  /* background-color: rgb(17, 97, 77); */
  /* height: 165rem; */

  /* border: 5px solid black; */
  /* overflow: auto; */
  /* overflow: hidden; */
}

/* somethings change here */
.hey {
  /* overflow: hidden; */
  /* border: 5px solid black; */
  width: 100%;
  /* height: 100%;  */
  /* margin-top: 10px; */
  /* margin: auto; */
  position: fixed;
  top: 0;
  /* right: 5; */
  /* border: 2px solid black; */
  height: 5rem;
  background-color: white;
}


/* Navbar styles */
.navbar {

  /* border:5px solid orange; */
  /* background-color: white; */
  /* z-index: 1000; */
  border-radius: 2px;
  background-image: linear-gradient(to right, rgba(245, 232, 232, 0.918), rgba(205, 245, 249, 0.918));
  color: rgb(15, 2, 2);
  /* margin-top: 3rem; */
  padding: 10px 0px 0px 0px;
  height: 4rem;
  /* border: 2px solid black; */

}




.container {
  /* border: 5px solid rgb(219, 201, 201); */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-brand {
  /* border: 10px solid rgb(5, 163, 44); */
  text-decoration: none;
  color: white;
  font-size: 24px;
  font-weight: bold;
}

.nav-links {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.nav-item {
  margin: 0 15px;
}

.nav-item a {
  text-decoration: none;
  color: rgb(32, 30, 30);
  font-family: 'Comfortaa', cursive;
  font-weight: bold;
}



.thiosis {
  color: rgb(252, 238, 238);
}


#smallbox {
  /* border: 5px solid brown; */
  /* border: 2px solid black; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 2rem;

}

.logotitle {
  /* border: 10px solid green; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.imsg {
  /* border: 3px solid pink; */
  width: 8rem;
  height: 2rem;
}

.imsng {
  /* border: 3px solid pink; */
  width: 10rem;
  height: 4rem;
}

.thisisbtnh {
  background-color: black;
  color: white;
  border-radius: 6px;
  margin: auto;
}


.outofsmall:hover {
  box-shadow: 0px 8px 5px 5px rgba(109, 105, 112, 0.429);
  /* Customize the shadow as needed */
}

.outofsmall {
  /* border: 5px solid rgb(56, 8, 8); */
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0re 4rem 0rem 4rem;
}


.thisistheseconddiv {
  /* border: 10px solid rgb(15, 14, 14); */
  background-image: linear-gradient(to right, rgba(225, 208, 225, 0.918), rgba(205, 245, 249, 0.918));
  /* background-color: rgb(212, 214, 214); */
  border-radius: 8px;
  /* padding-bottom: 5rem; */
  padding: 5rem 0rem 5rem 0rem;
  width: 80%;
  /* margin: auto; */
  box-shadow: 0px 5px 15px gray;
  display: flex;
  flex-direction: column;
  /* text-align: center; */
  margin: auto
}

.thisistheseconddiv:hover {
  box-shadow: 0px 5px 10px 2px gray inset;
}

.thisistheseconddiv h1,
.thisistheseconddivn h1 {
  font-family: 'Raleway', sans-serif;
}

.thisistheseconddivn {
  /* border: 1px solid black; */
  /* background-color: rgb(212, 214, 214); */

  background-image: linear-gradient(to right, rgba(205, 245, 249, 0.918), rgba(249, 214, 252, 0.918));
  border-radius: 8px;
  padding-bottom: 5rem;
  width: 80%;
  margin: auto;
  box-shadow: 0px 5px 15px gray;
  display: flex;
  flex-direction: column;

}

.thisistheseconddivn:hover {
  box-shadow: 0px 5px 10px 2px gray inset;
}

.thelastdiv {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
}

.whattheysay {
  /* margin-top: 10rem; */
  margin: 10rem 0rem 2rem 0rem;

  /* border: 10px solid green; */
  width: 100vw;
  box-sizing: border-box;
  /* display: inline-block; */
}

.insidesay {
  margin-top: 3rem;
  /* border: 5px solid brown; */
  padding: 0rem 0rem 1rem 0rem;
  width: 100vw;
  overflow: hidden;

  display: flex;
  flex-direction: row;
  justify-content: space-around;

}


footer {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  /* border: 5px black solid; */
  right: 5;
  background-color: rgb(112, 110, 110);
  color: #fff;
  text-align: center;
  font-weight: bold;
  font-size: large;
  height: 5rem;

  /* color: rgb(77, 62, 81); */
}

.hidden {
  display: none;

}




.mylinks {
  text-decoration: none;
  color: black;
  font-weight: bold;
}

.readmore {
  /* border: 5px solid peachpuff; */
  flex-direction: column;
  display: flex;
  justify-content: center;
  overflow: visible;
}






/* Media query for small screens */
@media (max-width: 999px) {
  #smallbox {
    display: flex;
    /* border: 5px solid orange; */
    flex-direction: column;
  }

  .thisistheseconddiv,
  .thisistheseconddivn {
    width: 110%;
    margin: 0rem 5rem 0rem 5rem;

  }

  .optimus {
    font-size: larger;
    height: 50rem;
    /* border: 5px solid black; */
  }

  .insidesay {
    /* border: 6px solid green; */

    display: flex;
    flex-direction: column;
    width: 30rem;
    margin: 3rem 2rem 0rem 2rem;
    /* height: 80rem; */
    /* border: 5px solid black; */
  }



  .nav-links {
    display: none;
    flex-direction: column;

  }



  #navbar-toggle {
    display: flex;
    flex-direction: column;
    cursor: pointer;
  }


  .whattheysay {
    /* border: 4px solid black; */
    width: 100%;
  }




}



/* _____________ */

@media (min-width: 1000px) {
  .promise {
    display: none;
  }
}


/* Media query for large screens (769px and above) */
@media (min-width: 1599px) {


  .imsg {
    width: 12rem;
    height: 4rem;
  }

  #insidethetoogle {
    display: none;
  }


  .hey {
    height: 9rem;
    /* border: red solid 2px ; */

  }

  .navbar {
    height: 8.9rem;
  }

  .optimus {
    height: 190rem;
    /* border: 5px solid black; */
  }

  .nav-item a {
    /* border: 5px solid pink; */
    /* size: 5rem; */
    font-size: 2rem;
  }


  #smallbox {
    margin-top: 5rem;
    /* border: 5px solid green; */
  }

  .thisistheseconddiv h1,
  .thisistheseconddivn h1 {
    /* border: 4px solid gray; */

    font-size: 6rem;
    text-align: center;
    padding-top: 2rem;
  }

  .thelastdiv h1 {
    font-size: 5.5rem;
  }

  .insidesay {
    margin-top: 7rem;
  }





  /* Add more responsive styles for large screens here */
}