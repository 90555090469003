.line-with-shadow {
  display: inline-block; /* or display: block; depending on your layout needs */
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.line {
  width: 100px; /* Adjust the width of your line */
  height: 2px; /* Adjust the height/thickness of your line */
  background-color: rgb(249, 249, 249); /* Adjust the color of your line */
}



.App {
  display: inline-block;
  /* text-align: center; */
  padding: 20px;
  /* border: 5px solid red; */
  margin: 1rem 0rem 0rem 0rem;
  width: 100%;

}

select{
  border: 1px solid #F0E5F3;
  border-radius: 5px;
  background-color: #F0E5F3;
  width: 10.3rem;
}

.box {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  /* border: 2px solid rgb(36, 9, 187); */
  padding: 10px;
  margin-bottom: 10px;
}

button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}


input{
  background-color: #F0E5F3;
  border-radius: 5px;
  border: 1px solid #F0E5F3;
  
}

.slast{
  /* border: 5px solid gray; */
  display: flex;
  justify-content: end;
}

#AddT{
  /* border: 2px solid black; */
  width:12rem;
  border-radius: 5px;
  background-color: #8A31A8;
  height:2rem;
  color: white;
  padding-top: 0.3rem;
}

.mybtn{
  /* border: 5px solid orange; */
  padding-bottom: 2rem;
  display: inline-block;
  /* margin: auto; */
  left: 0;
  width: 100%;
  position: sticky;
  bottom:0;
}

#SaveD{
  width:15vw;
  border-radius: 5px;
  background-color: #8A31A8;
  color: white;
  /* border: 5px solid black; */
}



/* Media Queries for Small Screens (up to 768px) */
@media screen and (max-width: 900px) {
  #SaveD{
      width:10vw;
      font-size: smaller;
      border-radius: 5px;
      background-color: #8A31A8;
      color: white;
 
     
    }
  .box {
    display: flex;
    flex-direction: column;
  }
  .F {
      display: flex;
      flex-direction: column;
  }

  button {
    margin-top: 10px;
  }

  input{
      display: inline-block;
      width:100%;
      border: rgb(37, 4, 4) 5px solid;
  }
}

@media screen and (max-width: 500px) and (min-width: 100px) {
  input {
    display: inline-block;
    width: 100%;
    border: 2px solid rgb(240, 240, 247);
  }
}


/* Media Queries for Large Screens (above 768px) */
@media screen and (min-width: 2826px) {
  .box {
    /* display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px; */
    align-items: center;
  }

  button {
    margin-top: 0;
  }
  h1 > span{
      font-size: 8rem;
      
      /* border: 5px solid black; */
  }
}