.hereForm {
    /* Adjust padding as needed */
    border-radius: 10px; /* Adjust border radius as needed */
    box-shadow: -2px 2px 10px 0px rgba(0, 0, 0, 0.1); /* Adjust shadow properties as needed */
    height:3rem;
    /* border: 0.2px solid rgb(181, 176, 181); */
    box-shadow:inset  0px 4px 8px rgba(0, 0, 0, 0.1);
    
    margin: auto;
    background-color: #fafafa;


  
    /* width: 10rem; */

  }
  
.firstD{
    display: flex;
    justify-content: space-evenly;

}

.secondD{
    display: flex;
    justify-content: space-evenly;

}

.restD > div{
    margin-bottom: 1rem;
}


.lastbtns {
    position: fixed;
    bottom: 20px; /* Adjust this value as needed */
    left: 50%; /* Center horizontally */
    transform: translateX(-50%);
    
    color: white;
    margin-left: 4rem;
    
    border: none; /* Optionally remove borders */
    border-radius: 5px; /* Optionally add border radius */
    outline: none; /* Optionally remove outline */
    cursor: pointer; /* Add cursor pointer on hover */
    display: flex;
    flex-direction: row;
    /* border:2px solid red; */
    width: 40%;
    justify-content: space-around;
    
  }

  .foupbtn:hover{
    background-color: black;
  }
  