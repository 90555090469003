.thesmall{
    background-color: rgba(247, 251, 254, 0.982);
    /* border: 5px solid rgb(215, 20, 52); */
    display: flex;
    flex-direction: column;
    /* width: 28rem; */
    text-align: center;
    margin: 0rem 5rem 0rem 5rem;
    padding-top: 1rem;

}

.thesmall:hover {
    box-shadow: 2px 5px 10px 5px rgba(0, 0, 0, 0.3); /* Customize the shadow as needed */
  }

.thisisimg{
    /* border: 2px solid red; */
    overflow: hidden;
    /* margin-right: 10px; */
    padding: 5px;
}



/* media query for the big screen */
@media (min-width: 1599px) {

    .thesmall{
        /* border: 5px solid orange; */
        width: 60rem;
        text-align: center;

    }

    .mydofl{
        font-size: 1.5rem;
    }
  
}


@media (max-width: 999px) {  

    .thesmall{ 
        margin:auto;
    }


}