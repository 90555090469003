

.thisisend{
    padding: 5px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 2px 5px rgb(94, 90, 91) inset;
    /* border: 4px solid purple; */

}

.Card{
    width: 110% !important;
    height: 60%;
    border-radius: 20px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    transition: all 0.3s ease-out;
    /* background-color: white; */
    /* background-color: ; */
    margin-top: 5.4rem;
    overflow: hidden;
}
.CardD{
    width: 100% !important;
    height: 100%;
    border-radius: 20px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    transition: all 0.3s ease-out;
    /* background-color: white; */
    /* background-color: ; */
    overflow: scroll;
}
.ColC{
    /* margin: 0.5rem 0.5rem 0.5rem 0rem !important; */
    transition: 0.3sec;
    padding: 0rem 0.5rem;
    width: 100% !important;
    display: flex !important;
    height: 33rem;
    background-color: transparent;
   
}
.ColD{
    /* margin: 0.5rem 0.5rem 0.5rem 0rem !important; */
    transition: 0.3sec;
    padding: 0rem 0.5rem;
    width: 100% !important;
    display: flex !important;
    height: 33rem;
    background-color: transparent;
    
}
.SiteLayoutBackground{
    margin: 1rem 0rem 1rem 0.5rem;
    border:"2px solid red"
}

.big{
    display: flex;
    flex-direction: row;
    /* margin:auto; */
    /* justify-content: space-between; */
    /* width: 70vw; */
    /* border: 2px solid red; */
    /* overflow: hidden; */
    /* height: 100vh; */

}


.btngrp{
    /* border:2px solid black; */
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 50vw;
    padding: 0rem 2rem 0rem 2rem;
}

.divsecond{
    /* border: 2px solid black; */
    margin: 0rem 1rem 0rem 1rem;
    background-image: linear-gradient(to bottom, #f3d2fc, rgb(254, 252, 254));
    /* width: 100%; */
}


.thisisb{
    /* border: 2px solid rgb(216, 158, 249); */
    background-color: #efc9fc;
    box-shadow: 0px 2px 5px rgb(94, 90, 91) inset;
    background-image: linear-gradient(to bottom, #eaadfb, rgb(254, 252, 254));
    border: 2px solid purple;

}

.thisisbb{
    background-color: #efc9fc;
    box-shadow: 0px 2px 5px rgb(94, 90, 91);
    background-image: linear-gradient(to bottom, #eaadfb, rgb(254, 252, 254));
    border: 2px solid purple;
    

}




.smallbox{
    /* border: 2px solid black; */
    height: 20rem;
    /* width: 48rem; */
    /* margin: auto; */
}


