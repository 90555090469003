.headsthere{
    color: black;
    display: inline-block;
}

.headstatus{
    margin-left: 2rem;
    color: rgb(110, 87, 39);
    font-weight: bold;
    display: inline-block;

  
}


.vinayak{
    /* border: 5px solid black; */
    border-radius: 40px;
    background-image: linear-gradient(to Right, #f3d2fc, rgb(237, 225, 237));
    /* background-image: linear-gradient(to right, rgb(63, 135, 166), rgb(225, 58, 152)); */


    
    /* color: rgb(74, 218, 103);
    colo
    color: rgb(157, 153, 153); */
    /* background-color: rgb(244, 247, 237); */
    padding: 1rem;
    margin: 0rem;
    color: rgb(247, 169, 87);
    height: 100%;
}



.ekclass *{
    /* border: 5px solid red; */
    color: white;

    font-weight: bold;
    margin: 1rem;
}


.btnofmoadl{
    background-color: black;
    border-radius: 2rem;
    color: white;
    font-size: larger;
    margin: 1rem;
}

.disabled-style{
    background-color: rgb(229, 204, 204);
    cursor: none;
    opacity: 0.7;
}