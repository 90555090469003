.whatsaybox {
    height: 16rem;
    width: 21rem;
    /* border: 2px black solid; */
    border-radius: 10px;
    box-shadow: 2px 5px 10px rgb(88, 86, 86);
    padding-bottom: 2rem;

}

.whatsaybox:hover {
    width: 22rem;
    height: 17rem;
    box-shadow: 5px 10px 10px rgb(14, 14, 14);
}

.ohman {
    display: flex;
}

.thisismyimgforthesay{

    height: 5rem;
     width: 5rem;
}

.fortheimginthediv {
    margin-left: 1rem;
    overflow: hidden;
    width: 5rem;
    height: 5rem;
    border-radius: 20rem;
}

@media (min-width: 1599px) {
    .whatsaybox {
        width: 35rem;
        margin: 1rem;

        height: 22rem;
    }

    .whatsaybox p {
        font-size: 1.5rem;
    }

    .whatsaybox:hover {
        width: 36.5rem;
        height: 21.4rem;
        box-shadow: 5px 10px 10px rgb(14, 14, 14);
    }

    .thisismyimgforthesay{
        /* border: 5px solid black; */
        width: 8rem;
        height: 8rem;


    }

    .fortheimginthediv {
        /* border: 4px solid red; */
        border-radius: 20rem;
        height: 8rem;
        width: 8rem;
        overflow: hidden;
        margin-bottom: 1rem;
        /* overflow: h; */

    }
}



@media (max-width: 999px) {

   .whatsaybox{
    margin: 2rem 0rem 1rem 2rem;
    width: 20rem;
    /* border: 4px solid red; */

    height: 19rem;
   }

}