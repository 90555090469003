#thisisheadofbids{
    color: rgb(105, 14, 167);
    font-weight: lighter;
    
}

#theheadofbids{
    width: 100%;
    height: 100vh;
    /* border: 2px solid black; */
    display: flex;
    flex-direction: row;
}



#theonepd {flex-grow: 9; }
#thetwopd{flex-grow: 10;}


#theonepd{
    margin-right: 1rem;
    height: 45%;
    background-color: rgb(255, 232, 255);
    background-image: linear-gradient(to bottom, #f7e5f5, #dc8ffb);
    box-shadow: 0px 2px 5px inset;
    padding: 1rem 0.9rem 0rem 0rem;
    border-radius: 8px;
    
}
#thetwopd{
    /* border: 2px solid black; */
    display: flex;
    justify-content: start;
    flex-direction: column;
    margin-left: 1rem;
    height: 60%;
    background-color: rgb(255, 232, 255);
    box-shadow: 0px 2px 5px ;
    background-image: linear-gradient(to bottom, #f7e5f5, #dc8ffb);
    padding: 1rem 1rem 0rem 0rem;
    justify-content: start;
    overflow: hidden;
    border-radius: 8px;
}

.idkllnjh{
    /* display: flex; */
    display: flex;
    flex-direction: column;
    justify-content: start;
    /* border: 2px solid red; */
    justify-content: start;
    margin: 0rem 5rem 0rem 4rem;

     

}



.idkllnjh div{
    /* border: 2px solid black; */
    text-align: start;
    font-weight: bolder;
    color: rgb(88, 24, 127);
    font-size: larger;

    /* color: rgba(251, 221, 251, 0.226); */
    
    /* height: 20rem; */
}


.pedbidlb{

    padding-left: 2rem;
    display: flex;
    flex-direction: column;
}

.takethis{
    display: flex;
    flex-direction: row;
    justify-content: center;


}



#loadidul{
    overflow: scroll;
}

.thisisactualloadid{
    /* border: 2px solid green; */
    box-shadow: 0px 3px 10px gray inset;
    height: 2rem;
    font-size: larger;
    font-weight: bold;
    background-color: rgb(169, 113, 176);
    margin-bottom: 1rem;
    cursor: pointer;


    /* color: ; */

}