
* {
  box-sizing: border-box;
}

.search-container {
    display: flex;
    align-items: center;
    height: 100%;
    padding-left:30%;
    padding-right:30%;
    margin-right: auto;
  }
 .level-color > span  {
    color: red; /* Change the color to your desired value */
  }

  .align-right {
    display: flex;
    justify-content: flex-end;
  }
  
  .align-center {
    display: flex;
    justify-content: center;
  }
  .ant-menu-horizontal .ant-menu-item:hover {
    background-color: #ADD8E6;
    cursor: pointer;
  }
  /* .custom-menu-item > a {
  text-decoration: none;
} */
/* 
@media (max-width: 900px) {

.sidebar{
  border:2px solid red;
  width:5rem;

}} */