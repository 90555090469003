.container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #F0E5F3;
    /* position: relative; */
  }
  
  /* .logo {
    position: absolute;
    top: 20px;
    left: 20px;
    background: url('../../../assets/LOGO.png');
    background-position: top left;
    background-repeat: no-repeat;
    background-size: 200px;
    width: 200px;
    height: 200px;
  } */
  
  .form-container {
    width: 33rem;
    height: 33rem;
    background-color: white;
    border-radius: 20px;
    padding: 20px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .background {
    background-color: #eea7e3;
  }
  
  .custom-btn {
    background-color: #8A31A8;
    color: #fff;
    border-radius: 5px;
    width: 300px;
    height: 70px;
    font-size: 13px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  
  
  .arrow {
    font-size: 160px; /* Change the font-size to adjust the width of the arrow */
    color: #f6f0f1;
    position: absolute;
    top: 26%;
    left: 50%;
    transform: translate(-50%, -50%); /* Center the arrow symbol */
  }
  
  /* Add any additional styles as required */
  