.heretable{
    /* border: 10px solid black; */
    margin: 0rem 4rem 0rem 4rem;
    /* display: inline-block; */
    /* display: block; */
    /* display: inline; */
    /* width: 100%; */
    /* height: 25rem ; */
}





.theend{
    /* border: 2px solid red; */
    color: purple;
    /* display: inline-block; */
    font-weight: bolder;
    font-size: 1.5rem;
    /* width: 25rem; */
}

.totalv{
    background-image: linear-gradient(to Top, #d6b7e0, rgb(254, 252, 254));
    margin:0px 10px 0px 10px;
    /* border: 2px solid black; */
    border-radius: 5px;
 
}

.total{
    /* border: 2px solid rgb(23, 212, 51); */
    display:flex;
    justify-content: space-around;
    width: 80%;
    height: 2rem;
    /* margin: 10rem 10rem 0rem 0rem; */

}


.btngrp{
    /* border:2px solid black; */
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 50vw;
    padding: 0rem 2rem 0rem 2rem;
}



.thisisb{
    /* border: 2px solid rgb(216, 158, 249); */
    background-color: #efc9fc;
    box-shadow: 0px 2px 5px rgb(94, 90, 91) inset;
    background-image: linear-gradient(to bottom, #eaadfb, rgb(254, 252, 254));
    border: 2px solid purple;

}

.thisisbb{
    background-color: #efc9fc;
    box-shadow: 0px 2px 5px rgb(94, 90, 91);
    background-image: linear-gradient(to bottom, #eaadfb, rgb(254, 252, 254));
    border: 2px solid purple;
    

}


